<template>
  <div class="pt-1 w-full">
    <header-register class="" :showButtonView="previewButtonIsVisible" showUrl="/dcb/addDcb">DCB
    </header-register>
    <search-bar-register class="mt-5 mb-2 sm:mb-3" :title="`Pesquisa de DCB`" @input="filter = $event" />

    <main class="w-full sm:h-full">
      <b-table responsive="sm" :fields="fields" primary-key="DCB" :items="items" :filter="filter" show-empty
        empty-text="Não existem codigos DCB's cadastrados" :empty-filtered-text="`DCB's não encontrado`">
        <template #cell(`Codigo DCB`)="data">
          {{ Indice + (data.index + 1) }}
        </template>
        <template #cell(ações)="data">
          <div class="acoes">
            <div>
              <button @click="viewPage(data.item)">
                <EyeIcon class="w-5 mr-2 icon__actions" />
              </button>
            </div>
            <div>
              <button @click="editPage(data.item)">
                <EditIcon class="w-4 mr-2 icon__actions" />
              </button>
            </div>
            <div>
              <button @click="deletarFornecedor(data.item.id)">
                <DeleteIcon class="w-5 icon__actions" />
              </button>
            </div>
          </div>
        </template>
      </b-table>
      <Pagination :totalItems="totalItems" :itemsPerPage="itemsPerPage" @input="ListaFornecedores" text="DCB" />
    </main>
    <Modal v-if="modalDeleted" :modalIsOpen="modalDeleted" :closeModal="closeModal"
      :modalText="$t('commons.modalTextDeleted')" />
    <Modal v-if="modalIsOpen" :modalIsOpen="modalIsOpen" :closeModal="closeModal" :buttonOptions="true"
      :confirmarDelete="confirmarDelete" :DeleteId="deletar" :modalText="$t('commons.modalTextDelete')" />
  </div>
</template>

<script>
import HeaderRegister from "@/@core/components/register/HeaderRegister.vue";
import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";
import Modal from "@core/components/commons/modal/Modal.vue";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "dcb",
  components: {
    Pagination,
    Modal,
    SearchBarRegister,
    HeaderRegister,
    EyeIcon,
    EditIcon,
    DeleteIcon
  },
  data() {
    return {
      deletar: null,
      modalIsOpen: false,
      modalDeleted: false,
      Indice: 0,
      filter: null,
      totalItems: 2,
      itemsPerPage: 10,
      fields: [
        "CodigoDCB",
        { key: "Descrição", sortable: true },
        { key: "ações" },
      ],
      items: [{ "CodigoDCB": 1, Descrição: "Teste" },
      { "CodigoDCB": 2, Descrição: "Teste" }
      ],
    }
  },
  methods: {
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalDeleted = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    deletarFornecedor(id) {
      this.modalIsOpen = true;
      this.deletar = id;
    },
    addPage() {
      
      this.$router.push("/dcb/addDcb");
    },
    viewPage(id) {   
      this.$store.state.Cod = id.CodigoDCB
      this.$store.state.Desc = id.Descrição
      this.$router.push(`/dcb/viewDcb`);
    },
    editPage(id) {
      this.$store.state.Cod = id.CodigoDCB
      this.$store.state.Desc = id.Descrição
      this.$router.push("/dcb/editDcb");
    },
  },
};
</script>

<style>
hr {
  position: relative;
  right: 18px;
}

br {
  content: "";
  margin: 0.6em;
  display: block;
}


.title-dcb {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

.title-search-dcb {
  font-size: 19px;
  color: #5B6873;
  font-family: "Segoe_UI_Regular";
}

.form-inputStyleF {
  height: 34px;
  color: black;
  background: #e6e6e6;
  border: 1px #e6e6e6 solid;
  border-radius: 5px;
}

.titleThF {
  font-family: "Segoe_UI";
  text-align: left;
  margin-left: 30px;
  font-size: 1.4em;
  font-weight: normal;
  color: #5b6873;
}

.MtitleThF {
  height: 10px;
  width: 20px;
  font-family: "Segoe_UI";
  font-size: 0.9em;
  font-weight: normal;
  color: #5b6873;
}

.content-search {
  background-color: #e6e6e6;
}

.contentTdF {
  font-family: "Segoe_UI";
  text-align: left;
  color: #cf0209;
  font-size: 1.4em;
  font-weight: normal;

}

.btnIncluir {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #cf0209;
}

.del-alert {
  color: #cf0209;
  font-size: 1.2em;
  font-family: "Segoe_UI_Black_2";
}

.btn-del {
  margin-right: 10px;
  background: #c2c3c434;
  border-radius: 17px;
  padding: 8px 30px 8px 30px;
  color: #5B6873;
}

.acoes {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 207px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid white;
  border-radius: 0;
  outline: 0;
}

.content-modelGeneral {
  height: 20px;

}

.icon-trash {
  cursor: pointer;
  display: inline;
  width: 32px;
  height: 24px;
}

.icon-edit {
  cursor: pointer;
  display: inline;
  width: 15px;
  height: 15px;
}

.content-iconSearch-dcb {
  width: 23px;
  height: 23px;
  background: transparent url("/src/assets/img/lupa.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

@font-face {
  font-family: "Segoe_UI_Bold";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe_UI_Black_2";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Black_2.ttf) format("truetype");
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-dcb {
    font-size: 1.7em;
  }


  .btnIncluir {
    width: 90px;
    height: 35px;
    font-size: 1em;
  }






  .icon-trash {
    width: 22px;
    height: 14px;
  }

  .icon-edit {
    width: 10px;
    height: 10px;
  }

  .content-iconSearch-dcb {
    width: 13px;
    height: 13px;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .content-modelGeneral {
    margin-bottom: 80px;

  }

  .model-content1 {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 25px;
    font-size: 0.9em;
  }

  .btn-del {
    margin-right: 10px;
    background: #c2c3c434;
    border-radius: 17px;
    padding: 5px 25px 5px 25px;
    color: #5B6873;
    font-size: 0.9em;
  }

  .modal.show .modal-dialog {
    position: relative;
    top: 20%;
  }

  .form-inputStyleF {
    height: 24px;
  }


}
</style>